import '../styles/index.scss';
import {applyEvents} from './gaEvents';
// import {enablePaymentActiveSwitch} from './paymentActiveSwitch';
import {enableLangSelect} from './langSelect';
window.mobileMenu = (() => {
  let isOpen = false;
  let menu = document.querySelector('.mb-header-nav-container');
  let menuBtn = document.getElementById('nav-toggle');
  return {
    isOpen: () => isOpen,
    toggle: () => {
      if (isOpen) { // will close
        menu.classList.add("hide");
        menu.classList.remove("show");
        isOpen = false;
        menuBtn.innerHTML = '&#9776;';
      } else { // will open
        menuBtn.innerHTML = '&times;';
        menu.classList.add("show");
        menu.classList.remove("hide");
        menu.style.display = 'none';
        menu.style.display = 'unset';
        isOpen = true;
      }
    }
  };
})();

window.goTo = (selector) => {
  if( window.mobileMenu.isOpen() ) window.mobileMenu.toggle();
  document.querySelector(selector).scrollIntoView({
    behavior: 'smooth'
  });
};

window.showVideo = () => {
  let html = `<iframe src="https://www.youtube.com/embed/Q-XhAIkTvZg?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <a class="btn white">
      إغلاق
  </a>`;
  let container = document.getElementById('video-player');
  container.innerHTML = html;
  container.style.display = 'flex';
};

window.closeVideo = () => {
  let container = document.getElementById('video-player');
  container.style.display = 'none';
  container.innerHTML = '';
};

var demoFrame = document.getElementById('demo-frame');
var demoFrameContainer = document.getElementById('demo-frame-container');
window.openDemo = function ( url ) {
    demoFrameContainer.style.display = "block";
    demoFrame.setAttribute('src',url);
};

window.closeDemo = function(){
    demoFrameContainer.style.display = "none";
    demoFrame.setAttribute('src',"about:blank");
};

window.switchLevel = (level) => {
  let current = document.querySelector('#levels .actions .btn.dark');
  current.classList.add('white');
  current.classList.remove('dark');
  let next = document.querySelector(`#levels .actions .btn:nth-child(${level})`);
  next.classList.add('dark');

  document.querySelectorAll('.level-desc').forEach( item => item.style.display = "none");
  document.querySelector(`#level-${level}`).style.display = "block";
};

window.fnShowLabel = (idx) => {
  // document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).style.display = 'inherit';
  document.querySelector(`#floating-nav li:nth-child(${idx}) .circle`).classList.remove('fadeOutUp');
  document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).classList.remove('fadeOutUp');
  document.querySelector(`#floating-nav li:nth-child(${idx}) .circle`).classList.add('fadeInUp');
  document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).classList.add('fadeInUp');
};

window.fnHideLabel = (idx) => {
  // document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).style.display = 'none';
  document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).classList.remove('fadeInUp');
  document.querySelector(`#floating-nav li:nth-child(${idx}) .label`).classList.add('fadeOutUp');
};

(()=>{
  let urlParams = new URLSearchParams(window.location.search);
  let campaign = urlParams.get('refc');
  if( !campaign ) return;
  let cname = 'campaign';
  let d = new Date();
  d.setTime(d.getTime() + (7*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + campaign + ";" + expires + ";path=/";
})();

// Google analytics events
applyEvents();

// language selection
enableLangSelect();

// payment active switch
// enablePaymentActiveSwitch();