const LANGS = [
  { txt: "ع", url: "/index.ar.html" },
  { txt: "En", url: "/index.en.html" },
  { txt: "Fr", url: "/index.fr.html" }
];
const LANG_DEFAULT = LANGS[0];

export function enableLangSelect() {
  const langSelects = document.getElementsByClassName("lang-select");
  langSelects.forEach(langSelect => {
    langSelect.addEventListener("click", handleClickSelect);
    const langText = langSelect.querySelector(".lang-text");
    langText.innerText = getCurrentLang().txt;
  });
}

function handleClickSelect(e) {
  const select = e.currentTarget;
  const lang = getCurrentLang();
  toggleOptions(select, lang);
}

function toggleOptions(select, currentLang) {
  const options = select.querySelector(".lang-options");
  if (options.children.length > 0) {
    // remove all options
    options.innerHTML = "";
  } else {
    // append all langs except the current one
    LANGS.forEach(lang => {
      if (lang.txt === currentLang.txt) return;
      const option = document.createElement("span");
      option.innerText = lang.txt;
      option.onclick = e => {
        window.location = lang.url;
      };
      options.appendChild(option);
    });
  }
  options.classList.toggle("fade-out");
}

function getCurrentLang() {
  const lang = LANGS.find(lang => lang.url === window.location.pathname);
  return lang || LANG_DEFAULT;
}
