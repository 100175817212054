export function applyEvents() {
  const { gtag } = window;
  const language = extractLanguage();
  if (language && gtag) {
    gtag("set", { language });
  }
  // add ga events on all links
  document.getElementsByTagName("a").forEach(e => {
    e.addEventListener("click", event => {
      const el = event.currentTarget;
      sendEvent(el);
    });
  });

  // for other elements we use a custom class name
  document.getElementsByClassName("ga-event").forEach(e => {
    e.addEventListener("click", event => {
      const el = event.currentTarget;
      sendEvent(el);
    });
  });
}

// The locale is like : index.XX.html
function extractLanguage() {
  const { pathname } = window.location;
  const languageMatch = pathname.match(/\/index\.([a-z]+)\.html/);
  if (!languageMatch) {
    // no match
    return null;
  }
  const language = languageMatch[1];
  return language;
}

function sendEvent(el) {
  const { gtag } = window;
  const action = el.getAttribute("ga-action");
  const category = el.getAttribute("ga-category");
  const label = el.getAttribute("ga-label");

  if (!action || !category) {
    if (process.env.NODE_ENV !== "production") console.warn("no event on link");
    return;
  }
  if (process.env.NODE_ENV !== "production")
    console.log("send ga event :", { action, category, label });
  if (!gtag) return;
  gtag("event", action, {
    event_category: category,
    event_label: label
  });
}
